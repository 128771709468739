.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  /* background-image: linear-gradient(79deg, #7439db, #C66FBC 48%, #F7944D); */
  /* padding-left: 145px; */
  /* background-color: #025464; */
}

body {
  padding: 0;
  margin: 0;
}
a{
  text-decoration: none !important;
}

.appPadLeft{
  padding-left: 260px;
}

.appPadRight{
  padding-right: 260px;
}

.font-xl{
  font-size :30px;
}
.font-l{
  font-size :26px;
}
.font-m{
  font-size :22px;
}

.bg_blue_cls{
  background-color: #025464 ;
}


.bg_white_cls{
  /* background-color: #ffffff ; */
  background-color: #adb5bd ;
}

.accessDeniedMsg{
  display: none;
}

/* /staff - Add Staff BTN */
#btn_addStaff{
  display: none;
}

/* Add Lookups BTN */
#btn_addLookups{
  display: none;
}

#btn_addLookupsChild{
  display: none;
}

#btn_addPermission{
  display: none;
}

#viewAllRoleBtn{
  display: none;
}

#addRoles_Btn{
  display: none;
}

.showmsg {
    color: red;
}

.invalid{
  color: #495057;
  /* background-color: #fff; */
  border-color: #D30000;
  outline: 0;
  /* box-shadow: 0 0 0 0.2rem #d3000087; */
  }
  
  .valid{
  color: #495057;
  background-color: #fff;
  border-color: #078422;
  outline: 0;
  /* box-shadow: 0 0 0 0.2rem #07842275; */
  }

  .decline_form{
    display: none;
  }

  /* .deleteLookupsChild, .viewLookupsChild, .editLookupsChild{
    display:none;
  } */

  .leaveSettingDiv{
    display: none;
  }
  #editLeaveForRolesDiv{
    display: none;
  }
  #btn_addReminder{
    display: none;
  }
  /* #btn_leaveSettings{
    display: none;
  } */

  /* 17/1/24 */

  .form-group{
    align-self: flex-end;
  }
  /* .timeInOutDiv, */
  .addLeaveDiv,
  .test{
    display: none;
  }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}