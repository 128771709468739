.header {
  font-family: fantasy;
}

.contentView {
  margin-left: 10px;
  margin-right: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}
