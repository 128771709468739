.profile_img{
    background-repeat:no-repeat;
    height: 500px;
}

.detail_div{
    align-self: center;
}

.profileItem h1,
.profileItem h2,
.profileItem p    {
    color:#000000;
}