/* Home Page Section Start */
.middle {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    text-align: center;
}

/* Home Page Section End */

/*======================================================== Login CSS Start======================================================== */

*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body{
    background-color: #080710;
}
.background{
    width: 430px;
    height: 520px;
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}
.background .shape{
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
}
.shape:first-child{
    background: linear-gradient(
        #1845ad,
        #23a2f6
    );
    left: -80px;
    top: -80px;
}
.shape:last-child{
    background: linear-gradient(
        to right,
        #ff512f,
        #f09819
    );
    right: -30px;
    bottom: -80px;
}
.login_form{
    height: 520px;
    width: 400px;
    background-color:rgb(0 0 0 / 63%);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
}
.login_form *{
    font-family: 'Poppins',sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}
.login_form h3{
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
}

.login_form label{
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}
.login_form input{
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255,255,255,0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}
::placeholder{
    color: #e5e5e5;
}
.login_form button{
    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.margintop{
    margin-top: 50px;
    background-color: #2192ea;
    color:#ffffff
}

/*======================================================== Login CSS End ======================================================== */
#addLookupsForm{
    display: none;
}

#addPermissionForm{
    display: none;
}

#addRoleForm{
    display: none;
}

/* Add Staff CSS */
.form-group{
    padding: 10px !important;
}

.fa-asterisk {
    font-size: 10px;
    color: transparent;
}

.input-group-text{
    background-color: transparent !important;
    border: none !important;
}

/* Hide buttons  */
.btn_updateLookups_div{
    display: none;
}

#userManagementForm{
    display: none;
}
/* Permissions.js */
.updatePermissionDiv{
    display: none;
}

/* lookups parent*/
.updateLookupsDiv{
    display: none;
}

.noneActiveDataTableDiv{
    display: none;
}

/* lookups child */
#addLookupsChildForm{
    display: none;
}

/* Staff Js Template */
.staffTemplateDiv{
    list-style:none;
}

.staffProfileList a{
    text-decoration: none !important;
    color: #ffffff !important;
}

.border-bg{
    border: none !important;
    background-color: transparent !important;
}

.showDDL{
    display: block !important;
}

/* .hideDDL {
    display: none !important;
} */

.genderDDL{
    /* width: 95% !important; */
}

.staff-bf{
    background-color: #025464;
}

.border_top_bottom_right{
    border-top-right-radius:10px;
    border-bottom-right-radius:10px;
    
}
.border_top_bottom_left{
    border-top-left-radius:10px;
    border-bottom-left-radius:10px;
}

.greyLabelCls{
    font-size: 14px;
}

#addRemindersForm{
    display: none;
}